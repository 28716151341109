import ScrollToEventMixin from '~/mixins/ScrollToEventMixin'

export default {
  mixins: [ScrollToEventMixin],
  props: {
    contact: {
      type: Object,
      default() {
        return this.$store.state.globals.contact
      },
    },
  },
  created() {
    this.$on('scrolledTo', () => {
      import('@theme/assets/scss/inspirum-font.scss')
    })
  },
  data() {
    return {
      pdfFilePath: '/data/dotacni_projekty_2023.pdf',
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
  },
}
